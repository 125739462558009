// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

export const environment = {
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
let webUrl = 'http://localhost:8080/iotify-web';
let adminUrl = 'http://localhost:8090/iotify-admin/';
// const webSocketUrl = 'ws://localhost:8080/iotify-web/dashboard';
webUrl = 'https://remon.in/iotify-web';
adminUrl = 'https://remon.in/iotify-admin/';
const webSocketUrl = 'wss://remon.in/iotify-web/dashboard';


const adminSocketUrl = adminUrl;

export const dashboardDataSocket = {
  staticVariable: undefined,
  liveVariable: undefined,
  liveStructure: undefined,
};

export const serverUrl = {
  webUrl: webUrl,
  adminUrl: adminUrl,
  webSocketUrl: webSocketUrl,
  adminSocketUrl: adminSocketUrl,
};
// export var commonJson={
// loginDtoJson:undefined
// };
