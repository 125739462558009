import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { LoadingController, Platform, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { WebSocketConnectionService } from './web-socket-connection.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Subject, Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class CommonService implements OnDestroy {
  isLoading: boolean;
  // chartWidth: number = this.platform.width();
  loaderdata = {
    isloading: false,
    message: '',
    timer: 0,
  };
  headerJson: string = this.parsingjsoninfo();
  customtimers = [];
  // header: string = localStorage.getItem('headerJson');
  isEmtyObj = (obj) => {
    return !Boolean(Object.keys(obj).length);
  };
  isnotEmty = (obj) => Boolean(Object.keys(obj).length);
  isEmtyArr = (arr) => {
    return !Boolean(arr.length);
  };
  subject = new Subject<any>();
  componentsSubject = new Subject<number>();
  // subscriptions = new Subscription();
  constructor(
    private router: Router,
    public activeRoute: ActivatedRoute,
    public loadingController: LoadingController,
    public toastController: ToastController,
    public webSocketConnectionServices: WebSocketConnectionService,
    public screenOrientation: ScreenOrientation,
    private platform: Platform,
   // private loc: Location,
  ) {
    // let mypro = this.screenOrientation.onChange().subscribe((o) => {
    //   if (this.screenOrientation.type == 'landscape-primary') {
    //     let setwidthtime = setTimeout(() => {
    //       this.chartWidth = this.platform.width();
    //       this.componentsSubject.next({
    //         id: 'chartwidth',
    //         chartwidth: this.chartWidth,
    //       });
    //       console.log(
    //         this.screenOrientation.type,
    //         'orientaion type',
    //         this.platform.width()
    //       );
    //       clearTimeout(setwidthtime);
    //     }, 1200);
    //   }
    // });
    // this.subscriptions.add(mypro);
    // console.log(this.subscriptions, 'subscr');
  }
  preventivemaintenanceScale(imgpath,text,hourcumulative){
    return`   <button class="preventivebutton" style="margin: 0 auto; display: block; border-radius: 45px !important;
    background: white; width: 80px; height: 80px; border: 7px solid #a58d8d; 
    transition: 0.1s;">
     <img src="assets/img/grid/preventive4.png" style="width: 52px; height: 46px; margin: 3px 0px -10px -8px;">
     <span style="display: block; text-align: center; margin-bottom: 0px; font-size: small;">Reset</span>
   </button>
   <label style="display: block; text-align: center; margin-bottom: 0px; font-size: 11px; line-height: 100%;" class="ng-binding">Preventive
     Maintenance<br>${hourcumulative ||0} Hrs Due</label>`
  }
  preventivesetClick(id){

  }
  convertDateString = (date: string) => {
    if (date) return new Date(date.replace(' ', 'T')).getTime();
  };

  dataSharing(): Observable<any> {
    return this.subject.asObservable();
  }

  ngOnDestroy(): void {
    // console.log('service destroy');
    
    // this.subscriptions.unsubscribe();
  }
  parsingjsoninfo(s?) {
    let json: string = s || localStorage.getItem('headerJson');
    let dashboardInfo = {
      header: '',
      reports: '',
      manage: '',
      companysettings: '',
      otherinfo: '',
    };
    // console.log('header called ', s);
    if (this.headerJson) return this.headerJson;
    if (!json) return '';
    let c = json.replace(/\\/g, '').replace(/\"/g, '').replace(/\'/g, '');
    let reportdata = c.slice(1, -1).split(',');
    let headerjs = {
      Dashboard: 'DASHBOARD',
      Manage_Fleets: 'MANAGE',
      Alerts: 'ALERTS',
      Reports: 'REPORTS',
      Alarms: 'ALARMS',
      result: '',
    };

    let myarr = reportdata.forEach((x) => {
      if (x.endsWith('Report')) {
        dashboardInfo.reports += `${x},`;
      } else if (x == 'company_management') {
        dashboardInfo.companysettings = '1';
      } else dashboardInfo.otherinfo += `${x},`;
    });
    Object.entries(headerjs).forEach((x) => {
      if (c.includes(x[0])) {
        headerjs.result += x[1] + ',';
      }
    });
    dashboardInfo.header = headerjs.result.slice(0, -1);
    //console.log(dashboardInfo, 'dashboardinfoo');

    return (this.headerJson = headerjs.result.slice(0, -1));
  }
  // @HostListener('window:resize', ['$event'])
  // onWindowResize() {
  //   this.chartWidth = window.innerWidth;
  //   console.log(this.chartWidth, 'chatsize');
  // }
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  };
  logout() {
    //this.commonService.presentLoader();
    // console.log('log Out!!!');
    localStorage.clear();
    sessionStorage.clear();
    this.headerJson='';
    // this.loc.replaceState('/login');

    this.router.navigate(['/login']);
    // this.router.navigateByUrl('login', { replaceUrl: true }).finally(() => {
    //   // this.commonService.dismissLoader();
      
    // });
  }

  async presentLoader(classname?: string, customduration: number = 9000) {
    // let lang = sessionStorage.getItem("language");
    // let message = lang == 'ar' ? "أرجو الإنتظار !" : "Please Wait!";
    let messagee = 'Loading...';
    if (this.loaderdata.isloading) return null;
    if (this.router.url == '/login') messagee = 'Please Wait!';
    this.loaderdata.message = messagee;
    let setmytime = setTimeout(() => {
      this.dismissLoader('selfclosing',1);
    }, customduration);
    this.customtimers.push(setmytime);
    this.loaderdata.isloading = true;

    // console.log('loader called', this.isLoading);
  }

  dismissLoader(classname?: string,from?) {
    // this.loaderdata.isloading = false;
    if(from){
      this.loaderdata.isloading=false;
    }else{
  let loader=    setTimeout(()=>{
        this.loaderdata.isloading=false;
        clearTimeout(loader);
      },1000)
    }
  
    // console.log(this.customtimers, 'before');
    clearTimeout(this.customtimers.pop());
    // console.log(this.customtimers, 'after');
  }

  signOut() {
    localStorage.clear();
    sessionStorage.clear();
    this.webSocketConnectionServices.closeConnection();
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }

  isPresent(arrayList: string[], checkList: string[], isMathched = false) {
    if (isMathched) return checkList.every((el) => arrayList.includes(el));
    return checkList.some((el) => arrayList.includes(el));
  }
  screenport() {
    this.screenOrientation.unlock();
    return this.screenOrientation
      .lock(this.screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY)
      .then(
        (success) => console.log('success', this.platform.width()),
        (failure) => console.log(failure)
      );
  }
  screenland(id = 1) {
    this.screenOrientation.unlock();
    if (this.screenOrientation.type == 'landscape-primary' && id) return null;
    return this.screenOrientation
      .lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE_PRIMARY)
      .then(
        (success) => {
          console.log('success', this.platform.width());
        },
        (failure) => console.log(failure)
      );
  }
  // appOnbackground(){
  //   this.platfm.resume.unsubscribe();
  //   this.screenport()
  // }
  segment(trends) {
    if (trends) this.screenport();
    //this.appPause=false
  }
  isDesktop(): boolean {
    return ['desktop', 'tablet'].some((platform) =>
      this.platform.platforms().includes(platform)
    );
  }
  arrayLooping(array: any[], callback?) {
    array.forEach((x, i) => {
      callback(x, i);
    });
  }
  getImportantVars(input) {
    let vars = {
      loginData: JSON.parse(localStorage.getItem('loginData')),
      loginSiteData: JSON.parse(localStorage.getItem('loginJson')),
    };
    return vars[input];
  }
  localstragevars = (name: string) =>
    JSON.parse(localStorage.getItem(name)) || null;
}
