import { Component, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { WebSocketConnectionService } from './services/web-socket-connection.service';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { CommonService } from './services/common.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  islandscape = false;
  isDesktop;
  isIos;
  mysettimes;
  preventEvent = 0;
  constructor(
    private platform: Platform,
    private device: Device,
    private socketConnection: WebSocketConnectionService,
    private statusBar: StatusBar,
    private commonService: CommonService,
    private screenOrientation: ScreenOrientation,
    private router: Router,
    // private loc: Location,
  ) {
    this.initializeApp();
    // this.router.events.subscribe((e: RouterEvent) => {
    //   //  console.log('urls', router.url, e);
    //   //   if(router.url!='/login')

    //   this.navigationInterceptor(e);
    // });
    // window.onresize = () => {
    //   this.preventEvent++;
    //   if (this.preventEvent === 1) {
    //     let resizetime = setTimeout(() => {
    //       console.log(this.platform.width(), this.preventEvent);
    //       this.commonService.componentsSubject.next(this.platform.width());
    //       this.preventEvent = 0;
    //       clearTimeout(resizetime);
    //     }, 1000);
    //   }
    // };
  }

  initializeApp = () => {
  //   this.router.events.subscribe((event) => {
  //     if (event instanceof NavigationStart) {
  //         // Show loading indicator
  //         // console.log('first ah ');
        
    
   
          
  //     }

    
  // });
    this.platform.ready().then(() => {
    //  console.log(this.platform,this.platform.url().split('/'));
    // console.log('first ah ne!!!');
    // let pagename=this.platform.url().split('/');
    // if(pagename[pagename.length-1]!='login'){
    //   let islogged=JSON.parse(localStorage.getItem('loggedInfo'));
    //   let basesecureobj={
    //     trough:()=>{},
    //     notThrough:()=>{
          
    //       this.commonService.logout();
    //       // Location.replaceState('/product/apple');
    //     }
    //   }
    //   let basesecure= (islogged?.['logged'])?'trough':'notThrough';
    //   // console.log('basesecure',basesecure,islogged);
      
    //   basesecureobj[basesecure]();
    // }

      
    });
    this.platform.pause.subscribe(
      (e) => {
        this.closeWebsocket();
        this.islandscape =
          this.screenOrientation.type !== 'portrait-primary' ? true : false;
      },
      (err) => console.log(err)
    );
    // this.platform.pause.subscribe(
    //   () => {
    //   },
    //   (err) => console.log(err)
    // );
    this.platform.resume.subscribe(
      () => {
        setTimeout(() => {
          if (this.islandscape) {
            this.commonService.screenland(0);
          }
        }, 200);
      },
      (err) => console.log(err)
    );
    // this.statusBar.overlaysWebView(true);

    window.addEventListener('beforeunload', () => {
      this.closeWebsocket();
    });
    this.isDesktop = this.commonService.isPresent(this.platform.platforms(), [
      'desktop',
      'tablet',
    ]);
    this.isIos = this.platform.platforms().includes('ios');
    this.statusBar.styleLightContent();
    if (!this.isDesktop) this.statusBar.show();
  };

  closeWebsocket = () => {
    this.socketConnection.closeConnection();
  };

  ngOnDestroy() {
    alert('exit');
  }
  onChangeTab(path) {
    this.router.navigateByUrl(`/tabs/${path}`);
  }
  // navigationInterceptor(event: RouterEvent): void {

  //   //this.commonService.presentLoader();

  //   // if (event instanceof NavigationEnd) {
  //   //   this.mysettimes = setTimeout(() => {
  //   //     this.commonService.dismissLoader();
  //   //   }, 2000);
  //   // }

  //   // // Set loading state to false in both of the below events to hide the spinner in case a request fails
  //   // if (event instanceof NavigationCancel) {
  //   //   this.commonService.dismissLoader();
  //   // }
  //   // if (event instanceof NavigationError) {
  //   //   this.commonService.dismissLoader();
  //   // }
  // }
}
